<template>
    <div class="area-code fix-background">
        <div :class="['section',$i18n.locale]" ref="sectionBox" @touchstart="touchstart" @touchmove="touchmove">
            <header>
                <nav @click="onclose">
                    <van-icon name="arrow-left" color="#FFFFFF" size="16px" />
                </nav>
            </header>
            <main>
                <div class="search">
                    <van-icon class="icon" name="search" color="rgba(255, 255, 255, 0.5)" size="1.5em" />
                    <input class="search-input" v-model="searchValue" type="text" :placeholder="$t('register.search')" @input="search">
                </div>
                <div class="list">
                    <p v-for="(item, index) in searchList" :key="index" @click="onclick(item.code)">
                        <span class="label">{{ item.country }}</span>
                        <span>{{ item.code }}</span>
                    </p>
                </div>
            </main>
        </div>
        <div class="fixedBox"></div>
    </div>
</template>

<script>

export default {
    name: 'areaCode',
    data() {
        return {
            searchValue: '',
            cnList: [
                { country: '阿富汗', code: '93' },
                { country: '阿尔巴尼亚', code: '355' },
                { country: '阿尔及利亚', code: '213' },
                { country: '美属萨摩亚', code: '1684' },
                { country: '安道尔', code: '376' },
                { country: '安哥拉', code: '244' },
                { country: '安圭拉', code: '1264' },
                { country: '安提瓜和巴布达', code: '1268' },
                { country: '阿根廷', code: '54' },
                { country: '亚美尼亚', code: '374' },
                { country: '阿鲁巴', code: '297' },
                { country: '澳大利亚', code: '61' },
                { country: '奥地利', code: '43' },
                { country: '阿塞拜疆', code: '994' },
                { country: '巴哈马', code: '1242' },
                { country: '巴林', code: '973' },
                { country: '孟加拉国', code: '880' },
                { country: '巴巴多斯', code: '1246' },
                { country: '白俄罗斯', code: '375' },
                { country: '比利时', code: '32' },
                { country: '伯利兹', code: '501' },
                { country: '贝宁', code: '229' },
                { country: '百慕大群岛', code: '1441' },
                { country: '不丹', code: '975' },
                { country: '玻利维亚', code: '591' },
                { country: '波斯尼亚和黑塞哥维那', code: '387' },
                { country: '博茨瓦纳', code: '267' },
                { country: '巴西', code: '55' },
                { country: '文莱', code: '673' },
                { country: '保加利亚', code: '359' },
                { country: '布基纳法索', code: '226' },
                { country: '布隆迪', code: '257' },
                { country: '柬埔寨', code: '855' },
                { country: '喀麦隆', code: '237' },
                { country: '加拿大', code: '1' },
                { country: '开普', code: '238' },
                { country: '开曼群岛', code: '1345' },
                { country: '中非共和国', code: '236' },
                { country: '乍得', code: '235' },
                { country: '中国', code: '86' },
                { country: '智利', code: '56' },
                { country: '哥伦比亚', code: '57' },
                { country: '科摩罗', code: '269' },
                { country: '库克群岛', code: '682' },
                { country: '哥斯达黎加', code: '506' },
                { country: '克罗地亚', code: '385' },
                { country: '库拉索', code: '599' },
                { country: '塞浦路斯', code: '357' },
                { country: '捷克', code: '420' },
                { country: '刚果民主共和国', code: '243' },
                { country: '丹麦', code: '45' },
                { country: '吉布提', code: '253' },
                { country: '多米尼加', code: '1767' },
                { country: '多米尼加共和国', code: '1809/1829/18' },
                { country: '厄瓜多尔', code: '593' },
                { country: '埃及', code: '20' },
                { country: '萨尔瓦多', code: '503' },
                { country: '赤道几内亚', code: '240' },
                { country: '厄立特里亚', code: '291' },
                { country: '爱沙尼亚', code: '372' },
                { country: '埃塞俄比亚', code: '251' },
                { country: '法罗群岛', code: '298' },
                { country: '斐济', code: '679' },
                { country: '芬兰', code: '358' },
                { country: '法国', code: '33' },
                { country: '法属圭亚那', code: '594' },
                { country: '法属波利尼西亚', code: '689' },
                { country: '加蓬', code: '241' },
                { country: '冈比亚', code: '220' },
                { country: '格鲁吉亚', code: '995' },
                { country: '德国', code: '49' },
                { country: '加纳', code: '233' },
                { country: '直布罗陀', code: '350' },
                { country: '希腊', code: '30' },
                { country: '格陵兰岛', code: '299' },
                { country: '格林纳达', code: '1473' },
                { country: '瓜德罗普岛', code: '590' },
                { country: '关岛', code: '1671' },
                { country: '瓜地马拉', code: '502' },
                { country: '几内亚', code: '224' },
                { country: '几内亚比绍共和国', code: '245' },
                { country: '圭亚那', code: '592' },
                { country: '海地', code: '509' },
                { country: '洪都拉斯', code: '504' },
                { country: '中国香港', code: '852' },
                { country: '匈牙利', code: '36' },
                { country: '冰岛', code: '354' },
                { country: '印度', code: '91' },
                { country: '印度尼西亚', code: '62' },
                { country: '伊拉克', code: '964' },
                { country: '爱尔兰', code: '353' },
                { country: '以色列', code: '972' },
                { country: '意大利', code: '39' },
                { country: '象牙海岸', code: '225' },
                { country: '牙买加', code: '1876' },
                { country: '日本', code: '81' },
                { country: '约旦', code: '962' },
                { country: '哈萨克斯坦', code: '7' },
                { country: '肯尼亚', code: '254' },
                { country: '基里巴斯', code: '686' },
                { country: '科威特', code: '965' },
                { country: '吉尔吉斯斯坦', code: '996' },
                { country: '老挝', code: '856' },
                { country: '拉脱维亚', code: '371' },
                { country: '黎巴嫩', code: '961' },
                { country: '莱索托', code: '266' },
                { country: '利比里亚', code: '231' },
                { country: '利比亚', code: '218' },
                { country: '列支敦士登', code: '423' },
                { country: '立陶宛', code: '370' },
                { country: '卢森堡', code: '352' },
                { country: '中国澳门', code: '853' },
                { country: '北马其顿', code: '389' },
                { country: '马达加斯加', code: '261' },
                { country: '马拉维', code: '265' },
                { country: '马来西亚', code: '60' },
                { country: '马尔代夫', code: '960' },
                { country: '马里', code: '223' },
                { country: '马耳他', code: '356' },
                { country: '马提尼克', code: '596' },
                { country: '毛里塔尼亚', code: '222' },
                { country: '毛里求斯', code: '230' },
                { country: '马约特', code: '262' },
                { country: '墨西哥', code: '52' },
                { country: '摩尔多瓦', code: '373' },
                { country: '摩纳哥', code: '377' },
                { country: '蒙古', code: '976' },
                { country: '黑山', code: '382' },
                { country: '蒙特塞拉特岛', code: '1664' },
                { country: '摩洛哥', code: '212' },
                { country: '莫桑比克', code: '258' },
                { country: '缅甸', code: '95' },
                { country: '纳米比亚', code: '264' },
                { country: '尼泊尔', code: '977' },
                { country: '荷兰', code: '31' },
                { country: '新喀里多尼亚', code: '687' },
                { country: '新西兰', code: '64' },
                { country: '尼加拉瓜', code: '505' },
                { country: '尼日尔', code: '227' },
                { country: '尼日利亚', code: '234' },
                { country: '挪威', code: '47' },
                { country: '阿曼', code: '968' },
                { country: '巴基斯坦', code: '92' },
                { country: '帕劳', code: '680' },
                { country: '巴勒斯坦', code: '970' },
                { country: '巴拿马', code: '507' },
                { country: '巴布亚新几内亚', code: '675' },
                { country: '巴拉圭', code: '595' },
                { country: '秘鲁', code: '51' },
                { country: '菲律宾', code: '63' },
                { country: '波兰', code: '48' },
                { country: '葡萄牙', code: '351' },
                { country: '波多黎各', code: '1787' },
                { country: '卡塔尔', code: '974' },
                { country: '刚果共和国', code: '242' },
                { country: '留尼汪', code: '262' },
                { country: '罗马尼亚', code: '40' },
                { country: '俄罗斯', code: '7' },
                { country: '卢旺达', code: '250' },
                { country: '圣基茨和尼维斯', code: '1869' },
                { country: '圣露西亚', code: '1758' },
                { country: '圣彼埃尔和密克隆岛', code: '508' },
                { country: '圣文森特和格林纳丁斯', code: '1784' },
                { country: '萨摩亚', code: '685' },
                { country: '圣马力诺', code: '378' },
                { country: '圣多美和普林西比', code: '239' },
                { country: '沙特阿拉伯', code: '966' },
                { country: '塞内加尔', code: '221' },
                { country: '塞尔维亚', code: '381' },
                { country: '塞舌尔', code: '248' },
                { country: '塞拉利昂', code: '232' },
                // { country: '新加坡', code: '65' },
                { country: '圣马丁岛（荷兰部分）', code: '1721' },
                { country: '斯洛伐克', code: '421' },
                { country: '斯洛文尼亚', code: '386' },
                { country: '所罗门群岛', code: '677' },
                { country: '索马里', code: '252' },
                { country: '南非', code: '27' },
                { country: '韩国', code: '82' },
                { country: '西班牙', code: '34' },
                { country: '斯里兰卡', code: '94' },
                { country: '苏里南', code: '597' },
                { country: '斯威士兰', code: '268' },
                { country: '瑞典', code: '46' },
                { country: '瑞士', code: '41' },
                { country: '中国台湾', code: '886' },
                { country: '塔吉克斯坦', code: '992' },
                { country: '坦桑尼亚', code: '255' },
                { country: '泰国', code: '66' },
                { country: '东帝汶', code: '670' },
                { country: '多哥', code: '228' },
                { country: '汤加', code: '676' },
                { country: '特立尼达和多巴哥', code: '1868' },
                { country: '突尼斯', code: '216' },
                { country: '土耳其', code: '90' },
                { country: '土库曼斯坦', code: '993' },
                { country: '特克斯和凯科斯群岛', code: '1649' },
                { country: '乌干达', code: '256' },
                { country: '乌克兰', code: '380' },
                { country: '阿拉伯联合酋长国', code: '971' },
                { country: '英国', code: '44' },
                { country: '美国', code: '1' },
                { country: '乌拉圭', code: '598' },
                { country: '乌兹别克斯坦', code: '998' },
                { country: '瓦努阿图', code: '678' },
                { country: '委内瑞拉', code: '58' },
                { country: '越南', code: '84' },
                { country: '英属处女群岛', code: '1284' },
                { country: '美属维尔京群岛', code: '1340' },
                { country: '也门', code: '967' },
                { country: '赞比亚', code: '260' },
                { country: '津巴布韦', code: '263' }
            ],
            enList: [
                { country: 'Afghanistan', code: '93' },
                { country: 'Albania', code: '355' },
                { country: 'Algeria', code: '213' },
                { country: 'American Samoa', code: '1684' },
                { country: 'Andorra', code: '376' },
                { country: 'Angola', code: '244' },
                { country: 'Anguilla', code: '1264' },
                { country: 'Antigua and Barbuda', code: '1268' },
                { country: 'Argentina', code: '54' },
                { country: 'Armenia', code: '374' },
                { country: 'Aruba', code: '297' },
                { country: 'Australia', code: '61' },
                { country: 'Austria', code: '43' },
                { country: 'Azerbaijan', code: '994' },
                { country: 'Bahamas', code: '1242' },
                { country: 'Bahrain', code: '973' },
                { country: 'Bangladesh', code: '880' },
                { country: 'Barbados', code: '1246' },
                { country: 'Belarus', code: '375' },
                { country: 'Belgium', code: '32' },
                { country: 'Belize', code: '501' },
                { country: 'Benin', code: '229' },
                { country: 'Bermuda', code: '1441' },
                { country: 'Bhutan', code: '975' },
                { country: 'Bolivia', code: '591' },
                { country: 'Bosnia and Herzegovina', code: '387' },
                { country: 'Botswana', code: '267' },
                { country: 'Brazil', code: '55' },
                { country: 'Brunei', code: '673' },
                { country: 'Bulgaria', code: '359' },
                { country: 'Burkina Faso', code: '226' },
                { country: 'Burundi', code: '257' },
                { country: 'Cambodia', code: '855' },
                { country: 'Cameroon', code: '237' },
                { country: 'Canada', code: '1' },
                { country: 'Cape Verde', code: '238' },
                { country: 'Cayman Islands', code: '1345' },
                { country: 'Central African Republic', code: '236' },
                { country: 'Chad', code: '235' },
                { country: 'China', code: '86' },
                { country: 'Chile', code: '56' },
                { country: 'Colombia', code: '57' },
                { country: 'Comoros', code: '269' },
                { country: 'Cook Islands', code: '682' },
                { country: 'Costa Rica', code: '506' },
                { country: 'Croatia', code: '385' },
                { country: 'Curacao', code: '599' },
                { country: 'Cyprus', code: '357' },
                { country: 'Czech', code: '420' },
                { country: 'Democratic Republic of the Congo', code: '243' },
                { country: 'Denmark', code: '45' },
                { country: 'Djibouti', code: '253' },
                { country: 'Dominica', code: '1767' },
                { country: 'Dominican Republic', code: '1809/1829/18' },
                { country: 'Ecuador', code: '593' },
                { country: 'Egypt', code: '20' },
                { country: 'El Salvador', code: '503' },
                { country: 'Equatorial Guinea', code: '240' },
                { country: 'Eritrea', code: '291' },
                { country: 'Estonia', code: '372' },
                { country: 'Ethiopia', code: '251' },
                { country: 'Faroe Islands', code: '298' },
                { country: 'Fiji', code: '679' },
                { country: 'Finland', code: '358' },
                { country: 'France', code: '33' },
                { country: 'French Guiana', code: '594' },
                { country: 'French Polynesia', code: '689' },
                { country: 'Gabon', code: '241' },
                { country: 'Gambia', code: '220' },
                { country: 'Georgia', code: '995' },
                { country: 'Germany', code: '49' },
                { country: 'Ghana', code: '233' },
                { country: 'Gibraltar', code: '350' },
                { country: 'Greece', code: '30' },
                { country: 'Greenland', code: '299' },
                { country: 'Grenada', code: '1473' },
                { country: 'Guadeloupe', code: '590' },
                { country: 'Guam', code: '1671' },
                { country: 'Guatemala', code: '502' },
                { country: 'Guinea', code: '224' },
                { country: 'Guinea-Bissau', code: '245' },
                { country: 'Guyana', code: '592' },
                { country: 'Haiti', code: '509' },
                { country: 'Honduras', code: '504' },
                { country: 'Hong Kong', code: '852' },
                { country: 'Hungary', code: '36' },
                { country: 'Iceland', code: '354' },
                { country: 'India', code: '91' },
                { country: 'Indonesia', code: '62' },
                { country: 'Iraq', code: '964' },
                { country: 'Ireland', code: '353' },
                { country: 'Israel', code: '972' },
                { country: 'Italy', code: '39' },
                { country: 'Ivory Coast', code: '225' },
                { country: 'Jamaica', code: '1876' },
                { country: 'Japan', code: '81' },
                { country: 'Jordan', code: '962' },
                { country: 'Kazakhstan', code: '7' },
                { country: 'Kenya', code: '254' },
                { country: 'Kiribati', code: '686' },
                { country: 'Kuwait', code: '965' },
                { country: 'Kyrgyzstan', code: '996' },
                { country: 'Laos', code: '856' },
                { country: 'Latvia', code: '371' },
                { country: 'Lebanon', code: '961' },
                { country: 'Lesotho', code: '266' },
                { country: 'Liberia', code: '231' },
                { country: 'Libya', code: '218' },
                { country: 'Liechtenstein', code: '423' },
                { country: 'Lithuania', code: '370' },
                { country: 'Luxembourg', code: '352' },
                { country: 'Macao', code: '853' },
                { country: 'North Macedonia', code: '389' },
                { country: 'Madagascar', code: '261' },
                { country: 'Malawi', code: '265' },
                { country: 'Malaysia', code: '60' },
                { country: 'Maldives', code: '960' },
                { country: 'Mali', code: '223' },
                { country: 'Malta', code: '356' },
                { country: 'Martinique', code: '596' },
                { country: 'Mauritania', code: '222' },
                { country: 'Mauritius', code: '230' },
                { country: 'Mayotte', code: '262' },
                { country: 'Mexico', code: '52' },
                { country: 'Moldova', code: '373' },
                { country: 'Monaco', code: '377' },
                { country: 'Mongolia', code: '976' },
                { country: 'Montenegro', code: '382' },
                { country: 'Montserrat', code: '1664' },
                { country: 'Morocco', code: '212' },
                { country: 'Mozambique', code: '258' },
                { country: 'Myanmar', code: '95' },
                { country: 'Namibia', code: '264' },
                { country: 'Nepal', code: '977' },
                { country: 'Netherlands', code: '31' },
                { country: 'New Caledonia', code: '687' },
                { country: 'New Zealand', code: '64' },
                { country: 'Nicaragua', code: '505' },
                { country: 'Niger', code: '227' },
                { country: 'Nigeria', code: '234' },
                { country: 'Norway', code: '47' },
                { country: 'Oman', code: '968' },
                { country: 'Pakistan', code: '92' },
                { country: 'Palau', code: '680' },
                { country: 'Palestine', code: '970' },
                { country: 'Panama', code: '507' },
                { country: 'Papua New Guinea', code: '675' },
                { country: 'Paraguay', code: '595' },
                { country: 'Peru', code: '51' },
                { country: 'Philippines', code: '63' },
                { country: 'Poland', code: '48' },
                { country: 'Portugal', code: '351' },
                { country: 'Puerto Rico', code: '1787' },
                { country: 'Qatar', code: '974' },
                { country: 'Republic Of The Congo', code: '242' },
                { country: 'Réunion Island', code: '262' },
                { country: 'Romania', code: '40' },
                { country: 'Russia', code: '7' },
                { country: 'Rwanda', code: '250' },
                { country: 'Saint Kitts and Nevis', code: '1869' },
                { country: 'Saint Lucia', code: '1758' },
                { country: 'Saint Pierre and Miquelon', code: '508' },
                { country: 'Saint Vincent and The Grenadines', code: '1784' },
                { country: 'Samoa', code: '685' },
                { country: 'San Marino', code: '378' },
                { country: 'Sao Tome and Principe', code: '239' },
                { country: 'Saudi Arabia', code: '966' },
                { country: 'Senegal', code: '221' },
                { country: 'Serbia', code: '381' },
                { country: 'Seychelles', code: '248' },
                { country: 'Sierra Leone', code: '232' },
                // { country: 'Singapore', code: '65' },
                { country: 'Saint Maarten (Dutch Part)', code: '1721' },
                { country: 'Slovakia', code: '421' },
                { country: 'Slovenia', code: '386' },
                { country: 'Solomon Islands', code: '677' },
                { country: 'Somalia', code: '252' },
                { country: 'South Africa', code: '27' },
                { country: 'South Korea', code: '82' },
                { country: 'Spain', code: '34' },
                { country: 'Sri Lanka', code: '94' },
                { country: 'Suriname', code: '597' },
                { country: 'Swaziland', code: '268' },
                { country: 'Sweden', code: '46' },
                { country: 'Switzerland', code: '41' },
                { country: 'Taiwan', code: '886' },
                { country: 'Tajikistan', code: '992' },
                { country: 'Tanzania', code: '255' },
                { country: 'Thailand', code: '66' },
                { country: 'Timor-Leste', code: '670' },
                { country: 'Togo', code: '228' },
                { country: 'Tonga', code: '676' },
                { country: 'Trinidad and Tobago', code: '1868' },
                { country: 'Tunisia', code: '216' },
                { country: 'Turkey', code: '90' },
                { country: 'Turkmenistan', code: '993' },
                { country: 'Turks and Caicos Islands', code: '1649' },
                { country: 'Uganda', code: '256' },
                { country: 'Ukraine', code: '380' },
                { country: 'United Arab Emirates', code: '971' },
                { country: 'United Kingdom', code: '44' },
                { country: 'United States', code: '1' },
                { country: 'Uruguay', code: '598' },
                { country: 'Uzbekistan', code: '998' },
                { country: 'Vanuatu', code: '678' },
                { country: 'Venezuela', code: '58' },
                { country: 'Vietnam', code: '84' },
                { country: 'Virgin Islands, British', code: '1284' },
                { country: 'Virgin Islands, US', code: '1340' },
                { country: 'Yemen', code: '967' },
                { country: 'Zambia', code: '260' },
                { country: 'Zimbabwe', code: '263' }
            ],
            list: [],
            searchList: [],
            loading: false,
            finished: false,
        }
    },
    created() {
        this.searchList = this.list = this.$i18n.locale === 'zh-CN' ? this.cnList : this.enList
    },
    methods: {
        search() {
            console.log('搜索框输入', this.searchValue)
            let a = this.searchValue; //用户输入的字符串
            let str = ['',...a,''].join('.*'); //转化成正则格式的字符串
            let reg = new RegExp(str) //正则
            this.searchList = this.list.filter(item => reg.test(item.country) || reg.test(item.code))
        },
        onclick(code) {
            console.log('点击了', code)
            this.$emit('selectCode', `+${code}`)
        },
        // 关闭
        onclose() {
            this.$emit('selectCode')
        },
        touchstart () {
            this.lastY = event.changedTouches[0].clientY // 点击屏幕时记录最后一次Y坐标
        },
        touchmove () {
            let y = event.changedTouches[0].clientY
            let st = this.$refs.sectionBox.scrollTop // 滚动条高度
            if (y >= this.lastY && st <= 0) { //若滚动到顶部且为下拉情况
                this.lastY = y
                event.preventDefault()
            }
            let clientHeight = document.querySelector('.section').clientHeight
            let scrollHeight = document.querySelector('.section').scrollHeight
            if ((st + clientHeight === scrollHeight) && y < this.lastY) { // 若滚动到底部且为上拉情况
                this.lastY = y
                event.preventDefault()
            }
            this.lastY = y  
        }
    }
}
</script>

<style lang="less" scoped>
    .area-code {
        overflow: scroll;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, #262849, #07090f);
        
        header {
            height: 48px;
            padding: 0 15px;
            display: flex;
            align-items: center;
        }

        main {
            padding: 0 15px;
            .search {
                display: flex;
                align-items: center;
                height: 56px;
                background: rgba(45, 47, 86, 0.4);
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.34);
                border-radius: 4px;
                .icon {
                    margin-left: 16px;
                }
                .search-input {
                    margin-left: 8px;
                    background-color: transparent;
                    color: #FFFFFF;
                    font-size: 13px;
                }
            }
            .list {
                margin-top: 12px;
                background: rgba(45, 47, 86, 0.4);
                border-radius: 4px;
                padding: 0 16px;
                p {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    line-height: 44px;
                    text-align: left;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.5);
                }

                .label {
                    color: #FFFFFF;
                }

                p:not(:last-child)::after {
                    position: absolute;
                    box-sizing: border-box;
                    content: ' ';
                    pointer-events: none;
                    right: 0;
                    bottom: -0.5px;
                    left: 0;
                    border-bottom: 1px solid #ebedf0;
                    -webkit-transform: scaleY(.5);
                    transform: scaleY(.5);
                }
            }
        }
        &.fix-background {
            height: 100%;
            overflow: hidden;
        }
    }
    .section {
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        main {
            padding-bottom: 50px;
        }
    }
    .fixedBox {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 7.68px;
    }
    .ar-AR{
         .search{
            padding-right: 10px;
        }
        .van-icon-arrow-left{
            transform: scaleX(-1);
        }
    }
</style>
